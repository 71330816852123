<template>
  <span ref="emojiRef" :style="{ fontSize: props.size + 'px', lineHeight: props.lineHeight }" />
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';
import { database, defaultEmoji } from './index';

const props = defineProps({
  emoji: {
    type: String,
    default: 'coffee',
  },
  size: {
    type: [Number, String],
    default: 16,
  },
  lineHeight: {
    type: String,
    default: 'auto',
  },
});
const emojiRef = ref(null);

const setEmoji = async () => {
  const [shortcode, tone] = String(props.emoji).split(':s_t:');
  const toneNumber = Number(tone);
  let emojiUnicode;
  try {
    const res = await database.getEmojiByShortcode(shortcode);
    if (emojiRef.value) {
      if (res) {
        emojiUnicode = res.unicode;
        if (res.skins && toneNumber) {
          const skin = res.skins.find((item) => item.tone === toneNumber);
          if (skin) {
            emojiUnicode = skin.unicode;
          }
        }
        emojiRef.value.textContent = emojiUnicode;
      } else {
        emojiRef.value.textContent = defaultEmoji.unicode;
      }
    }
  } catch (e) {
    console.log('e', e);
  }
};

watch(() => props.emoji, setEmoji, { immediate: true });
</script>

<style lang="scss"></style>
