import { Database } from 'emoji-picker-element';
import EmojiComponent from './EmojiIcon';
import EmojiTrigger from './EmojiTrigger';
import EmojiPickerComponent from './EmojiPickerComponent';

let database = null;
let defaultEmoji = null;

const Emoji = {
  install(Vue) {
    // , options
    Vue.component('EmojiIcon', EmojiComponent);
    Vue.component('EmojiPickerComponent', EmojiPickerComponent);
    Vue.component('EmojiTrigger', EmojiTrigger);
  },
};

async function init() {
  database = new Database();
  defaultEmoji = await database.getEmojiByShortcode('coffee');
}

init();

export default Emoji;
export { database, defaultEmoji };
