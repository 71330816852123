<template>
  <footer class="footer">
    <div class="footer__content">
      © Sliday, 2016-{{ currentYear }}
    </div>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  padding: 0 20px;
  background: $dark;
  display: flex;
  justify-content: center;

  @include sm {
    padding: 0 15px;
  }

  &__content {
    width: 100%;
    max-width: 1140px;
    margin: 14px 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $white;
  }
}
</style>
