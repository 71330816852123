import axios from 'axios';
import store from '../store';
import router from '../router';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  maxRedirects: 0,
});
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('karma_tg_auth');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('error', error)
    if (error.response && [401, 404].includes(error.response.status)) {
      store.commit('auth/setLoggedOut');
      router.push('/login');
    }
    return Promise.reject(error);
  },
);

export default api;
