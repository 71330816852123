import { createStore } from 'vuex';
import auth from './auth';
import teams from './teams';

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    teams,
  },
});

export default store;
