import { createI18n } from 'vue-i18n';
import en from './locales/en.yml';
import ru from './locales/ru.yml';

const messages = {
  en,
  ru,
};

// Get the browser's language setting
const browserLanguage = navigator.language.split('-')[0];

// Check if the browser's language is supported
const supportedLanguages = Object.keys(messages);
const defaultLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const i18n = createI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  legacy: false,
});

export default i18n;
