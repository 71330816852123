import { createApp } from 'vue'
import router from './router';
import store from './store';
import i18n from './i18n';
import App from './App.vue'
import Emoji from '@/components/emoji';

// Import the library CSS
import '@sliday/karma-ui-library/style.css';

const app = createApp(App);
(async () => {
  await store.dispatch('auth/init');
  const currentLocale = localStorage.getItem('karma_tg_locale') || 'en';
  i18n.global.locale.value = currentLocale;
  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(Emoji)
    .mount('#app');
})();
