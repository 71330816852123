<template>
  <emoji-picker v-show="isPickerVisible" ref="picker" :style="customStyle" class="light" @emoji-click="handleSelect" />
</template>

<script setup>
import 'emoji-picker-element';
import { defineExpose, defineProps, defineEmits, ref } from 'vue';

defineProps({
  customStyle: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['select']);

const isPickerVisible = ref(false);
const picker = ref(null);
const target = ref(null);

const close = () => {
  document.body.removeEventListener('click', onClickAway);
  isPickerVisible.value = false;
};

const handleSelect = (e) => {
  emit('select', e.detail);
};

const onClickAway = (e) => {
  if (e.target !== picker.value && picker.value) close();
};

const show = (e) => {
  target.value = e.target;
  isPickerVisible.value = true;
  setTimeout(() => document.body.addEventListener('click', onClickAway));
};

defineExpose({ show, close });
</script>

<style lang="scss" scoped>
.emoji-picker {
  position: absolute;
  border: 1px solid $basic-gray-01;
  box-shadow: 0 0 30px rgba(#000, 0.1);
  z-index: 10;
  height: 320px;
  ::v-deep(.emoji-mart-scroll) {
    overflow-y: hidden;
    padding-right: 0;
    scrollbar-width: thin;
    &:hover {
      overflow-y: scroll;
    }
  }
}
emoji-picker {
  position: absolute;
  --border-color: #fff;
  border-radius: 6px !important;
  overflow: hidden;
  border: 1px solid #8ca0b3;
  height: 320px;
  width: 351px;
  --num-columns: 6;
  --emoji-padding: 4px;

  @include sm {
    --num-columns: 6;
    --emoji-padding: 4px;
    width: 270px;
  }
}
</style>
