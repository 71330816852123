import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

function isAuthenticated() {
  return !!localStorage.getItem('karma_tg_auth');
}

function ensureAuth(to, from, next) {
  const isAuth = isAuthenticated();
  if (!isAuth && to.path !== '/login') {
    store.dispatch('auth/logout');
    next('/login');
  } else if (isAuth && to.path === '/login') {
    next('/');
  } else {
    if (to.redirectedFrom && to.redirectedFrom.query && to.redirectedFrom.query.platform_id) {
      localStorage.setItem('karma_tg_chat', to.redirectedFrom.query.platform_id)
    }
    next();
  }
}

const routes = [
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('../views/TeamsPage.vue'),
    meta: {},
  },
  {
    path: '/new-chat',
    name: 'NewChat',
    component: () => import('../views/NewChatPage.vue'),
    meta: {},
  },
  {
    path: '/new-team',
    name: 'NewTeam',
    component: () => import('../views/NewTeamPage.vue'),
    meta: {},
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue'),
    meta: {},
  },
    // Other routes...
  { path: '/', redirect: 'teams' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(ensureAuth);

export default router;
