<template>
  <component :is="props.tag" class="emoji-trigger" @click.exact="showPicker">
    <slot>
      <EmojiIcon :emoji="emoji" :size="size" />
    </slot>
    <EmojiPickerComponent ref="picker" :custom-style="props.customStyle" @select="handleEmojiClick" @click.stop />
  </component>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps({
  emoji: {
    type: String,
    default: 'coffee',
  },
  size: {
    type: [Number, String],
    default: 24,
  },
  customStyle: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'div',
  },
});

const emit = defineEmits(['select']);

const picker = ref(null);

const handleEmojiClick = (data) => {
  emit('select', `${data.emoji.shortcodes[0]}:s_t:${data.skinTone}`);
  picker.value.close();
};

const showPicker = (e) => {
  picker.value.show(e);
};
</script>

<style lang="scss">
.emoji-trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 86px;
  border-radius: 5px;
  border: 1px solid $basic-gray-01;
  background: $white;
  padding: 8px 10px;
  height: 40px;
  cursor: pointer;
}
</style>
