<template>
  <header class="header">
    <div class="header__box" :class="{'header__box--only-logo': !isAuth}">
      <div class="header__logo">
        <img src="@/assets/logo.svg" class="header__logo-img" />
        <span>Karma</span>
      </div>
      <div class="header__data" v-if="isAuth">
        <div class="header__user">
          <img :src="currentUser.photo_url" alt="avatar" class="header__user-avatar">
          <span class="header__user-name">{{ currentUser.full_name }}</span>
        </div>
        <div class="header__locales">
          <button class="header__locale" :class="{'header__locale_current': currentLocale === 'en'}" @click="switchLocale('en')">
            <img :src="en" alt="en">
          </button>
          <button class="header__locale" :class="{'header__locale_current': currentLocale === 'ru'}" @click="switchLocale('ru')">
            <img :src="ru" alt="ru">
          </button>
        </div>
        <button class="header__logout" @click="logOut">
          {{ $t('auth.logout') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import en from '@/assets/us-flag.svg';
import ru from '@/assets/ru-flag.svg';

const store = useStore();
const router = useRouter();
const { locale } = useI18n();

const isAuth = computed(() => {
  return store.state.auth.isLoggedIn;
});

const currentUser = computed(() => {
  return store.state.auth.currentUser;
});

const currentLocale = computed(() => {
  return locale.value;
});

const switchLocale = (newLocale) => {
  locale.value = newLocale;
  localStorage.setItem('karma_tg_locale', newLocale);
}
const logOut = async () => {
  await store.dispatch('auth/logout');
  router.push({name: 'Login'})
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  background: #fff;
  box-shadow:
    0px 8px 60px 0px rgba(35, 75, 111, 0.04),
    0px 8px 40px 0px rgba(75, 111, 143, 0.15),
    0px 6px 10px 0px rgba(117, 145, 171, 0.02);
  padding: 7px 15px;

  &__box {
    max-width: 1140px;
    margin: 0 auto;
    min-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--only-logo {
      justify-content: center;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    gap: 4px;
    font-weight: 600;
  }

  &__logo-img {
    object-fit: contain;
    width: 22px;
    height: 22px;
    border-radius: 8px;
  }

  &__data {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 1000px;
  }

  &__user-name {
    color: $dark;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
  }

  &__locales {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__locale {
    padding: 0 4px;
    height: 24px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }

    &_current {
      border-radius: 5px;
      border: 1px solid $basic-gray-00;
    }
  }
  
  &__logout {
    background-color: transparent;
    cursor: pointer;
    color: $dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
  }

}
</style>
