import api from '../plugins/axios';

function handleError(error) {
  console.error('API Error:', error);
}

export default {
  state: {},
  mutations: {},
  actions: {
    async create({ dispatch }, team) {
      try {
        const resp = await api.post('/teams/create', team);
        if (resp.status === 200) {
          await dispatch('auth/getCurrentUser', {}, { root: true });
        }
      } catch (e) {
        handleError(e);
      }
    },
    async connectChat({ dispatch }, data) {
      try {
        const resp = await api.post('/teams/connect-chat', data);
        if (resp.status === 200) {
          await dispatch('auth/getCurrentUser', {}, { root: true });
        }
      } catch (e) {
        handleError(e);
      }
    },
  },
  getters: {},
  namespaced: true,
};
